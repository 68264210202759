@import "core/index.scss";
.page.auth {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100%;
	padding: 4rem 0;

	@include media {
		padding: 0 0 4rem;
	}
}

.section.auth {
	width: 40rem;
	padding: 0;

	@include media {
		border: none;
		box-shadow: none;
	}

	.auth-nav {
		// position: absolute;
		// top: 0;
		// left: 0;
		width: 100%;
		padding: 0 3rem;
		border-bottom: .1rem solid $color-gray-light;
		height: 6rem;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-top: var(--safe-area-insets-top, env(safe-area-inset-top));
	}

		.nav-btn {
			@include font-medium;
			display: inline-flex;
			align-items: center;
			justify-content: flex-start;
			color: $color-text-blue;

			> i {
				margin-right: .7rem;
				font-size: 1rem;
			}
		}

	.auth-header {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: space-between;
		padding: 4rem 3rem 0;

		@include media {
			padding-top: 2rem;
		}

		i {
			font-size: 7.4rem;
			color: $color-text-light;
		}
	}

		.header-logo {
			height: 6.2rem;

			.img {
				height: 100%;
			}
		}

		.header-nav {
			width: calc(100% + 6rem);
			margin: 4rem -3rem 0;
			padding: 0 3rem 1.9rem;
			border-bottom: .1rem solid $color-gray;
		}

			.nav-link {
				@include font-semibold;
				position: relative;
				font-size: 1.8rem;
				padding: 0 2rem;
				line-height: 4rem;
				display: inline-block;
				border-radius: $radius-general;
				color: $color-text-light;
				transition: color $transition-hover ease;

				@include mediaMin {
					&:hover {
						color: $color-text-blue;
					}
				}

				&.active {
					background-color: $color-blue-bg;
					color: $color-text-blue;

					&:after {
						@include pseudo;
						width: 0;
						height: 0;
						border: .7rem solid transparent;
						border-top-color: $color-blue-bg;
						position: absolute;
						left: 50%;
						top: 100%;
						transform: translate3d(-50%, 0, 0);
						transition: opacity $transition-hover ease, transform $transition-hover ease;
					}
				}
				
				+ .nav-link {
					margin-left: .6rem;

				}
			}
	
	.auth-social {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		margin-top: 2.8rem;
	}

		.social-seperator {
			color: $color-text-lighter;
			margin: 0 0 2rem;
			text-align: center;
			width: 100%;
			
			span {
				position: relative;
				padding: 0 .5rem;

				&:before, &:after {
					@include pseudo;
					position: absolute;
					background-color: $color-text-lighter;
					height: .1rem;
					width: 4.7rem;
					left: 100%;
					bottom: 50%;
				}
	
				&:after {
					left: auto;
					right: 100%;
				}
			}

		}

		.social-btn {
			width: calc(50% - .5rem);
			justify-content: flex-start;
			padding-left: 1.5rem;

			&.full {
				width: 100%;
				justify-content: center;
			}
		}
	
	.auth-login {
		padding: 3rem;

		// .login-entry {}

			.entry-title {
				@include font-regular;
				font-size: inherit;
				color: $color-text-dark;
			}

			.entry-subtitle {
				font-size: 1.3rem;
				color: $color-text-light;
				line-height: 1.2em;
				margin: 1rem 0 2rem;
			}

			.entry-submit {
				margin-top: 4.5rem;
			}

		// .login-otp {}

			.otp-error {
				margin-top: 2rem;
				margin-bottom: -1rem;
			}

			.otp-title {
				@include font-semibold;
				font-size: 1.8rem;
				margin-top: 3rem;
			}

			.otp-subtitle {
				line-height: 1.3333em;
				margin-top: 1rem;

				strong {
					color: $color-text-blue;
				}
			}

			.otp-submit {
				margin-top: 5rem;
			}
	}

	.auth-register {
		padding: 1.5rem 3rem 3rem;

		.register-backbtn {
			margin-bottom: 1rem;
			transition: opacity $transition-hover ease, background-color $transition-hover ease, color $transition-hover ease, border-color $transition-hover ease;

			@include media {
				transition: opacity $transition-hover ease, background-color $transition-superfast ease, color $transition-superfast ease, border-color $transition-superfast ease;
			}

			&:disabled {
				opacity: 0;
			}
		}

		.register-loginmessage {
			font-size: 1.3rem;
			color: $color-text-light;
			line-height: 1.2em;
			margin: 1.5rem 0 0;

			.link {
				@include font-medium;
				color: $color-blue;
			}
		}

		.register-progress {
			display: flex;
			justify-content: space-between;

			+ .register-user {
				.user-form {
					border-top: .1rem solid $color-gray;
					margin-top: 2rem;
				}
			}
		}

			.progress-step {
				&.active {
					.step-wrap {
						background-color: $color-bg;
						color: $color-text-dark;
					}
				}
			}

				.step-wrap {
					display: flex;
					align-items: center;
					flex-flow: flex-start;
					padding: .7rem 1.3rem .7rem .8rem;
					color: $color-text-light;
					border-radius: $radius-general;
					cursor: default;
					transition: background-color $transition-hover ease, color $transition-hover ease;

					&.clickable {
						cursor: pointer;

						@include mediaMin {
							&:hover {
								background-color: $color-bg;
								color: $color-text-dark;
							}
						}
					}
				}

				.step-icon {
					font-size: 2rem;
					margin-right: 1rem;
				}

				.step-text {
					@include font-semibold;
					flex: 1 0 0;
					font-size: 1.2rem;
				}

					.text-num {
						@include font-semibold;
						display: block;
						color: $color-text-lightest;
						text-transform: uppercase;
						font-size: .9rem;
					}

		.register-userpoll {
			margin-top: 2.5rem;
		}
		
			.userpoll-questionwrap {
				padding: 2rem;
				box-shadow: $shadow-big;
				border: .1rem solid $color-gray;
				border-radius: $radius-general;
			}

			.userpoll-title {
				color: $color-text-dark;
				font-size: 1.5rem;
			}

			.userpoll-opts {
				margin-top: 2.4rem;

				&.error {
					.checkwrap {
						input {
							+ label {
								background-color: transparent;
							}
						}

						+ .checkwrap {
							border-top-color: $color-gray;
						}
					}
				}

				.input-opts {
					border: none;
					box-shadow: none;
				}

				.checkwrap {
					input {
						+ label {
							padding-left: 0;
						}

						&:checked {
							+ label {
								background-color: transparent;
							}
						}
					}

					&.opts-inner {
						input + label {
							padding-left: 1rem;
							min-height: 3rem;
							@include font-regular;
						}

						+ .opts-inner {
							border-top: none;
						}

						+ .checkwrap:not(.opts-inner) {
							margin-top: .5rem;
						}
					}
				}
			}

				.opts-seperator {
					display: block;
					margin-bottom: 1rem;
				}

			.userpoll-submit {
				margin-top: 2.2rem;
			}

			.userpoll-bottom {
				margin-top: 1.2rem;
				text-align: right;
			}

				.bottom-skip {
					text-decoration: underline;
					color: $color-text-blue;
				}

		.register-babies {
			margin-top: 2rem;

		}
		
			.babies-title {
				@include font-regular;
				font-size: inherit;
				color: $color-text-dark;
			}

			.babies-subtitle {
				font-size: 1.2rem;
				color: $color-text-lighter;
				margin: 1rem 0 2rem;
			}

			.babies-list {
				text-align: right;
			}

				.list-baby {
					display: flex;
					flex-flow: row wrap;
					justify-content: flex-end;
					border: .1rem solid $color-gray;
					border-radius: $radius-general;
					box-shadow: $shadow-big;
					padding: 2rem;

					+ .list-baby {
						margin-top: 2rem;
					}
				}

					.baby-remove {
						margin-bottom: 2rem;
					}

					.baby-input {
						width: calc(50% - 1rem);

						@include media {
							width: 100%;
						}

						&.name {
							width: 100%;
							margin-bottom: 2rem;
						}

						&.birthday {
							@include mediaMin {
								margin-right: 2rem;
							}

							@include media {
								margin-bottom: 2rem;
							}
						}
					}

				.list-remove {
					margin-top: 1rem;
				}

			.babies-submit {
				margin-top: 2rem;
			}

			.babies-bottom {
				margin-top: 1.2rem;
				text-align: right;
			}

				.bottom-skip {
					text-decoration: underline;
					color: $color-text-blue;
				}

		// .register-user {}

			// .user-form {
				
			// }

			.form-field {
				margin-top: 2rem;
				width: 100%;
				margin-left: 0;

				&.type-checkbox {
					+ .type-checkbox {
						margin-top: 0;
					}
				}
			}

			// .form-note {
			// 	background-color: $color-bg;
			// 	border-radius: $radius-general;
			// 	padding: 1.5rem;
			// 	font-size: 1.3rem;
			// }

			hr {
				border-color: $color-gray-light;
				margin: .8rem 0 -1rem;
			}

		// .register-otp {}

			.otp-backbtn {
				margin-top: 2rem;
				margin-bottom: 2rem;
			}
		
			.otp-message {
				line-height: 1.3333em;
			}

			.otp-submitbtn {
				margin-top: 3rem;
			}
	}

	.auth-pininput {
		margin-top: 2.4rem;

		&.error {
			input {
				border-color: $color-error;
			}
		}
		
		input {
			border: .1rem solid $color-gray;
			border-radius: $radius-general;
			width: 6rem;
			height: 8rem;
			text-align: center;
			font-size: 2.4rem;
			line-height: 7.8rem;
			transition: border-color $transition-hover ease;

			+ input {
				margin-left: 1rem;
			}
		}
	}

		.pininput-controls {
			margin-top: 2.7rem;
			color: $color-text-light;
			
			.controls-resend {
				text-decoration: underline;
				color: $color-text-dark;
			}

			.controls-timer {
				font-size: 1.2rem;
				margin-left: 1.1rem;
			}
		}

}